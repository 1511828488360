


























import Vue from "vue";
import TableRow from "./RowLayout.vue";
import Employee from "@/components/Employee.vue";
import { TableText } from "@/ui/Table";
import Arrow_svg from "@/assets/svg/arrow-wrapped.svg?inline";
import { Tab } from "@/ui";
import { Report } from "@/schemas/dashboard";
import { cleanDelta, deltaIsPositive, deltaIsNegative, deltaIsZero } from "@/utils/delta";

const toTwoSign = (t: string) => (t.length < 2 ? "0" + t : t);

export default Vue.extend({
  name: "TableReportsRow",
  props: {
    report: {
      type: Object as () => Report,
      required: true
    },
    isOpen: Boolean,
    isLast: Boolean
  },
  data: () => ({
    emptyVal: "--"
  }),
  computed: {
    delta(): string {
      let delta = this.report.delta;
      return cleanDelta(delta);
    },
    deltaIsZero(): boolean {
      return deltaIsZero(this.delta);
    },
    deltaIsNegative(): boolean {
      return deltaIsNegative(this.delta);
    },
    deltaIsInvalid(): boolean {
      return this.report.num_absences === this.report.records.length || this.deltaIsZero;
    },
    timeRecordExists(): boolean {
      return this.report.records.some(record => record.time_records.length > 0);
    },
    noSchedule(): boolean {
      return this.report.records.every(record => record.schedule_record === null);
    }
  },

  components: {
    TableRow,
    Employee,
    TableText,
    Tab,
    Arrow_svg
  }
});
