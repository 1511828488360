












import Vue from "vue";
import { ExportButton } from "@/ui";
import { Moment } from "moment";
import { exportReport, ExportReportFormat } from "@/api/dashboard";
import { TerminalModule } from "@/store/modules/terminal";

import { IDownloadButtonTooltips } from "@/types/DownloadButton.ts";

export default Vue.extend({
  components: {
    ExportButton
  },
  props: {
    from_date: {
      type: Object as () => Moment,
      required: true
    },
    till_date: {
      type: Object as () => Moment,
      required: true
    }
  },
  data: () => ({
    isLoading: false,
    isError: false
  }),
  computed: {
    disabled(): boolean {
      return !this.$cStore.terminal.checkReal() && this.searchIsEmpty;
    },
    terminalId(): TerminalModule["terminalId"] {
      return this.$cStore.terminal.terminalId;
    },
    searchIsEmpty(): boolean {
      return this.$cStore.employeeSearch.selectedSuggestions.length === 0;
    },

    downloadTooltip(): IDownloadButtonTooltips[] {
      return [
        {
          id: 0,
          icon: "template",
          text: `Отчет по сотрудникам`
        },
        {
          id: 1,
          icon: "template",
          text: `Отчет по дням`
        }
      ];
    }
  },
  methods: {
    async downloadReport(tooltipId: number) {
      if (this.disabled) return;
      let report_format =
        tooltipId === 0 ? ExportReportFormat.by_employee : ExportReportFormat.by_date;
      this.isLoading = true;
      const response = await exportReport({
        from_date: this.from_date,
        till_date: this.till_date,
        terminal: this.terminalId,
        report_format
      });
      this.isLoading = false;
      if (!response) {
        this.isError = true;
      } else {
        this.downloadFile(response, tooltipId);
      }
    },
    downloadFile(file: Blob, tooltipId: number) {
      const from_date = this.from_date.format("DD-MM-YYYY");
      const till_date = this.till_date.format("DD-MM-YYYY");
      const reportFormat = tooltipId === 0 ? "сотрудникам" : "дням";
      const terminal_name = this.$cStore.terminal.terminalName;
      let url = URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${terminal_name}. Отчет по ${reportFormat} с ${from_date} по ${till_date}.xlsx`;
      a.click();
      a.remove();
    }
  }
});
