import { render, staticRenderFns } from "./RowTemplate.vue?vue&type=template&id=5a38c613&scoped=true&"
import script from "./RowTemplate.vue?vue&type=script&lang=ts&"
export * from "./RowTemplate.vue?vue&type=script&lang=ts&"
import style0 from "./RowTemplate.vue?vue&type=style&index=0&id=5a38c613&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a38c613",
  null
  
)

export default component.exports