

























































































import Vue from "vue";
import { TableText } from "@/ui/Table";
import { Tab } from "@/ui";
const placeholder = require("@/assets/img/user-placeholder-orig.jpg");
import { ReportRecord, TerminalWithStatus, Employee } from "@/schemas/dashboard";
import { Moment } from "moment";
import TableRow from "./RowTemplate.vue";
import {
  cleanDelta,
  deltaIsPositive,
  minutesToTime,
  deltaToMinutes,
  deltaIsZero
} from "@/utils/delta";

interface Car {}

export default Vue.extend({
  data: () => ({
    emptyVal: "--"
  }),
  computed: {
    delta(): string {
      let delta = this.record.delta;
      return cleanDelta(delta);
    },
    deltaIsZero(): boolean {
      return deltaIsZero(this.delta);
    },
    deltaIsPositive(): boolean {
      return deltaIsPositive(this.delta);
    },
    absence(): boolean {
      return this.autoClosedExist || (this.shiftExist && this.shiftEnded && !this.employeeCame);
    },
    shiftPlannedTab(): boolean {
      return !this.employeeCame && this.shiftPlanned;
    },
    shiftPlanned(): boolean {
      return this.shiftExist && !this.shiftEnded;
    },
    onWork(): boolean {
      return this.employeeCame && this.employeNotLeft;
    },
    shiftEnded(): boolean {
      if (!this.record.schedule_record) return true;
      const scheduleEnd = this.$moment(this.record.schedule_record.end_time);
      const now = this.$moment();
      return now.diff(scheduleEnd) > 0;
    },
    //фигово что я не могу поставить guard на this в объекте
    employeeCame(): boolean {
      return !!this.record.time_records.length;
    },
    employeNotLeft(): boolean {
      const lastRecord = this.record.time_records[this.record.time_records.length - 1];
      return !!lastRecord && !lastRecord.leaving_time;
    },
    shiftExist(): boolean {
      return !!this.record.schedule_record;
    },
    autoClosedExist(): boolean {
      return !!this.record.time_records.find(timeRecord => timeRecord.auto_closed);
    },
    terminal(): TerminalWithStatus | undefined {
      return this.$cStore.terminal.terminals.find(terminal => terminal.id === this.record.terminal);
    },
    terminalName(): string {
      return this.terminal ? this.terminal.name : "";
    },
    terminalDate(): string {
      if (!this.terminal) return "";
      return this.$moment(this.record.date).format("D MMMM, dddd");
    },
    photoIn(): string {
      if (this.record.time_records.length === 0) {
        return placeholder;
      }
      return this.record.time_records[0].arrival_photo || placeholder;
    },
    photoOut(): string {
      if (this.record.time_records.length === 0) {
        return placeholder;
      }
      const lastRecord = this.record.time_records[this.record.time_records.length - 1];
      return lastRecord.leaving_photo || placeholder;
    },
    displayArrivalTime(): string {
      if (!this.record.time_records.length) return this.emptyVal;
      let firstArrivalDate = this.record.time_records[0].arrival_time;
      return this.formatDateToTimezoneTime(firstArrivalDate);
    },
    displayLeavingTime(): string {
      if (!this.record.time_records.length) return this.emptyVal;
      let lastLeavingDate = this.record.time_records[this.record.time_records.length - 1]
        .leaving_time;
      return this.formatDateToTimezoneTime(lastLeavingDate);
    },
    displayScheduleArrivalTime(): string {
      if (!this.record.schedule_record) return this.emptyVal;
      return this.formatDateToTimezoneTime(this.record.schedule_record.start_time);
    },
    displayScheduleLeavingTime(): string {
      if (!this.record.schedule_record) return this.emptyVal;
      return this.formatDateToTimezoneTime(this.record.schedule_record.end_time);
    },
    displayScheduleWorkTimeFormatted(): string {
      const scheduleWorkTimeMinutes = this.getScheduleWorkTime();
      if (scheduleWorkTimeMinutes === 0) return this.emptyVal;
      return minutesToTime(scheduleWorkTimeMinutes);
    },

    displayWorkTime(): string {
      if (!this.employeeCame || this.employeNotLeft) return this.emptyVal;
      const startTime = this.$moment(this.record.time_records[0].arrival_time);
      const endTime = this.$moment(
        this.record.time_records[this.record.time_records.length - 1].leaving_time
      );
      return this.formatTimeDifference(startTime, endTime);
    },
    showAttentionMarker(): boolean {
      return this.absence || !this.deltaIsPositive;
    }
  },
  props: {
    record: {
      type: Object as () => ReportRecord
    },
    employee: {
      type: Object as () => Employee
    },
    isLast: Boolean
  },
  methods: {
    formatDateToTimezoneTime(date?: string): string {
      if (!this.terminal || !date) return this.emptyVal;
      return this.$moment(date)
        .tz(this.terminal.timezone)
        .format("HH:mm");
    },
    formatTimeDifference(startTime: Moment, endTime: Moment) {
      const totalMinutes = endTime.diff(startTime, "minutes");
      return minutesToTime(totalMinutes);
    },
    lazyPhoto(src: string): { src: string; error: string; loading: string } {
      return {
        src: src || placeholder,
        error: placeholder,
        loading: placeholder
      };
    },
    rowClick(record: ReportRecord) {
      this.$emit("rowClick", record);
    },
    getScheduleWorkTime() {
      if (!this.record.schedule_record) return 0;
      let startTime = this.$moment(this.record.schedule_record.start_time);
      let endTime = this.$moment(this.record.schedule_record.end_time);
      return endTime.diff(startTime, "minutes");
    }
  },
  components: {
    TableText,
    TableRow,
    Tab
  },
  filters: {
    delta(val: string) {
      let time = val.substring(1).split(":");
      let sign = val[0] === "-" ? "- " : "+ ";
      return `${sign} ${time[0]}:${time[1]}`;
    }
  }
});
