




















import Vue from "vue";
import { TableWrap, TableLine } from "@/ui/Table";
import { ReportRecord, Employee } from "@/schemas/dashboard";
import TableRow from "./Row.vue";
import TableHeader from "./Header.vue";
import { RequiredProps } from "@/components/modals/ReportRecords.vue";

const appendZeroFilter = (value: number, expectedLength: number = 2): string => {
  let result = value.toString();
  while (result.length < expectedLength) {
    result = "0" + result;
  }
  return result;
};

interface ILazyPhoto {
  src: string | undefined;
  error: string;
  loading: string;
}

export default Vue.extend({
  components: {
    TableHeader,
    TableLine,
    TableWrap,
    TableRow
  },
  props: {
    records: {
      type: Array as () => ReportRecord[]
    },
    employee: {
      type: Object as () => Employee
    }
  },
  methods: {
    openModal(index: number) {
      const modalParams: RequiredProps = {
        activeIndex: index,
        records: this.records,
        employee: this.employee
      };
      this.$modal.show("reportRecords", modalParams);
    }
  }
});
