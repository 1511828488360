






import Vue from "vue";
import { findParent } from "./utils";

export default Vue.extend({
  name: "accordionHeader",
  data: () => ({
    isOpen: false
  }),
  props: {
    tag: {
      type: String,
      default: "div"
    }
  },
  created() {
    this.$on("open", this.open);
    this.$on("close", this.close);
  },
  methods: {
    toggle() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    },
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    }
  },
  watch: {
    isOpen(value) {
      const eventName = value ? "open" : "close";
      const parent = findParent(this.$parent, "accordionItem");
      if (parent) {
        parent.$emit(eventName);
      }
    }
  }
});
