
























import Vue from "vue";
import PageLayout from "@/components/pageWrap.vue";
import { Datepicker, DateRange } from "@/ui";
import TableReports from "@/components/TableReports";
import EmployeeSearch from "@/components/EmployeesSearch.vue";
import { TerminalWithStatus } from "@/schemas/dashboard";
import ExportReportBtn from "@/components/ExportReportBtn.vue";
import { Moment } from "moment";

interface ComponentData {
  dateRange: null | Date[];
  [p: string]: any;
}

export default Vue.extend({
  name: "reports",
  metaInfo: {
    title: "Отчеты"
  },
  props: {
    openEmployee: String
  },
  mounted() {
    this.dateRange = this.initDateRange();
  },
  // beforeDestroy() {
  //   this.$cStore.routeQuery.ADD_QUERY({ start: undefined, end: undefined });
  // },
  data(): ComponentData {
    return {
      downloadTooltip: [
        {
          id: 0,
          icon: "template",
          text: "Отчет по сотрудникам"
        },
        {
          id: 1,
          icon: "dayIcon",
          text: "Отчет по дням"
        }
      ],
      maxRangeDate: this.$moment().endOf("day"),
      dateRange: null
    };
  },
  computed: {
    searchIsEmpty(): boolean {
      return this.$cStore.employeeSearch.selectedSuggestions.length === 0;
    }
  },
  methods: {
    getRouteQuery(name: string) {
      let query = this.$route.query[name];
      if (Array.isArray(query)) {
        return query.find(q => !!q);
      }
      return query;
    },
    getDateRangeFromQuery() {
      let start = this.getRouteQuery("start");
      let end = this.getRouteQuery("end");
      return [
        start ? this.$moment(start, "DD.MM.YYYY") : null,
        end ? this.$moment(end, "DD.MM.YYYY") : null
      ];
    },
    initDateRange() {
      let start: Moment, end: Moment;
      let [routeStartDate, routeEndDate] = this.getDateRangeFromQuery();

      //не понял как поставить чтобы шла проверка(guard) сразу на 2 параметра...
      if (this.routeEndDateIsValid(routeStartDate, routeEndDate) && routeStartDate) {
        start = routeStartDate;
        end = routeEndDate;
      } else {
        end = this.maxRangeDate;
        if (this.routeStartDateIsValid(routeStartDate)) {
          start = routeStartDate;
        } else {
          //Monday
          if (end.day() === 1) {
            start = end
              .clone()
              .subtract({ week: 1 })
              .startOf("week");
          } else {
            start = end.clone().startOf("week");
          }
        }
      }
      return [start.toDate(), end.toDate()];
    },
    routeEndDateIsValid(start: Moment | null, end: Moment | null): end is Moment {
      return !!end && !!start && end.diff(start) > 0 && end.diff(this.maxRangeDate) < 0;
    },
    routeStartDateIsValid(d: Moment | null): d is Moment {
      return !!d && d.diff(this.maxRangeDate) < 0;
    },
    setDateRangeToRouteQuery(range: DateRange) {
      const start = range[0] ? this.$moment(range[0]).format("DD.MM.YYYY") : undefined;
      const end = range[1] ? this.$moment(range[1]).format("DD.MM.YYYY") : undefined;
      this.$router.push({
        query: {
          ...this.$route.query,
          start,
          end
        }
      });
    }
  },
  watch: {
    dateRange(range: DateRange) {
      this.setDateRangeToRouteQuery(range);
    }
  },
  components: {
    PageLayout,
    EmployeeSearch,
    Datepicker,
    TableReports,
    ExportReportBtn
  }
});
