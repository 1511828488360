




















import Vue from "vue";
import TableRow from "./RowTemplate.vue";
import { TableText } from "@/ui/Table";
export default Vue.extend({
  components: {
    TableRow,
    TableText
  }
});
