import Vue from "vue";

export const findChildren = (children: Vue[] | [], name: string): Vue[] | [] => {
  if (children.length === 0) return [];

  const validItems = new Array<Vue>();
  const invalidItems = new Array<Vue>();

  children.forEach(child => {
    if (child.$options.name === name) {
      validItems.push(child);
    } else {
      invalidItems.push(child);
    }
  });
  const reducer = (total: Vue[], val: Vue): Vue[] | [] => [...total, ...val.$children];
  const invalidItemsChildren = invalidItems.reduce(reducer, new Array<Vue>());
  return [...validItems, ...findChildren(invalidItemsChildren, name)];
};

export const findParent = (parent: Vue, name: string): Vue | false => {
  if (parent.$options.name === name) return parent;
  return parent.$parent ? findParent(parent.$parent, name) : false;
};
