




import Vue from "vue";
import { findChildren } from "./utils";

type ItemUid = number | string;
interface IComponentData {
  openedIds: ItemUid[];
  [propName: string]: any;
}

export default Vue.extend({
  name: "accordionGroup",
  props: {
    durationOpen: Number,
    durationClose: Number,
    tag: {
      type: String,
      default: "div"
    },
    multiple: {
      type: Boolean,
      default: false
    },
    defaultOpened: {
      type: Array as () => ItemUid[],
      default: (): ItemUid[] => []
    }
  },
  data: function(): IComponentData {
    return {
      openedIds: []
    };
  },
  created() {
    this.$on("open", this.itemOpen);
    this.$on("close", this.itemClose);
  },
  mounted() {
    if (this.defaultOpened && this.defaultOpened.length > 0) {
      this.updateChildrenState(this.defaultOpened);
    }
  },
  methods: {
    itemOpen(uid: ItemUid | null) {
      if (uid === null || this.openedIds.includes(uid)) return;
      if (!this.multiple) {
        this.openedIds = [];
      }
      this.openedIds.push(uid);
    },
    itemClose(uid: ItemUid | null) {
      this.openedIds = this.openedIds.filter(_id => _id !== uid);
    },
    updateChildrenState(activeChildren: ItemUid[]) {
      const items = findChildren(this.$children, "accordionItem");
      items.forEach(child => {
        if (activeChildren.includes(child.$props.uid)) {
          child.$emit("open");
        } else {
          child.$emit("close");
        }
      });
    }
  },
  watch: {
    openedIds(to: ItemUid[]) {
      this.updateChildrenState(to);
    }
  }
});
