






import Vue from "vue";
import { findChildren, findParent } from "./utils";
import type { slotBinding } from "./types";

export default Vue.extend({
  name: "accordionItem",
  data: () => ({
    isOpen: false
  }),
  props: {
    tag: {
      type: String,
      default: "div"
    },
    uid: [String, Number]
  },
  created() {
    this.$on("open", this.open);
    this.$on("close", this.close);
  },
  computed: {
    bindingMethods(): slotBinding {
      return {
        isOpen: this.isOpen,
        methods:{
          toggle: this.toggle,
          open: this.open,
          close: this.close,
        }
      };
    }
  },
  methods: {
    toggle() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    },
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    }
  },
  watch: {
    isOpen(value) {
      const eventName = value ? "open" : "close";
      const children = [
        ...findChildren(this.$children, "accordionHeader"),
        ...findChildren(this.$children, "accordionBody")
      ];
      children.forEach(child => child.$emit(eventName));
      const parent = findParent(this.$parent, "accordionGroup");
      if (parent) {
        parent.$emit(eventName, this.uid);
      }
    }
  }
});
