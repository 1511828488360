

























import Vue from "vue";
import { findParent } from "./utils";
import type { slotBindingBody } from "./types";

import resizeSensor from "vue-resize-sensor";

import gsap from "gsap";
type Tween = gsap.core.Tween;

interface IComponentData {
  activeTween: Tween | null;
  height: string | number;
  [propName: string]: any;
}

export default Vue.extend({
  name: "accordionBody",
  components:{
    resizeSensor,
  },
  data: function(): IComponentData {
    return {
      activeTween: null,
      isOpen: false,
      isClosed: true,
      height: 0,
      childHeight:0,
    };
  },
  props: {
    tag: {
      type: String,
      default: "div"
    },
    posAbsolute:{
      type: Boolean,
      default: false,
    },
    posAbsoluteTag: {
      type: String,
      default: "div"
    }
  },
  created() {
    this.$on("open", this.open);
    this.$on("close", this.close);
  },
  computed: {
    bindingMethods(): slotBindingBody {
      return {
        height: this.height,
        isOpen: this.isOpen,
        isClosed: this.isClosed,
        methods:{
          toggle: this.toggle,
          open: this.open,
          close: this.close,
        }
      };
    },
  },
  methods: {
    toggle() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    },
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    getAnimatedItem(){
      return this.posAbsolute ? this.$refs.posAbsoluteRef : this.$el;
    },
    animateOpen() {
      this.killActiveTween();
      this.isClosed = false;
      if(!this.posAbsolute){
        let animatedItem = this.getAnimatedItem();
        this.activeTween = gsap.to(animatedItem, {
          duration: 0.5,
          height: "auto",
          ease: "power2.in",
        });
      }
      else{
        let progress = {i:0};
        this.activeTween = gsap.to(progress,{
          i:1,
          duration:0.5,
          ease:"power2.in",
          onUpdate:()=>{
            this.updateSize(progress.i)
          },
          onComplete:()=>{
            this.checkSizeUpdates = true;
          }
        })
      }
    },
    animateClose() {
      this.killActiveTween();
      if(!this.posAbsolute){
        let animatedItem = this.getAnimatedItem();
        this.activeTween = gsap.to(animatedItem, {
          duration: 0.5,
          height: 0,
          ease: "power2.out",
        });
      }
      else {
        let progress = {i:1};
        this.activeTween = gsap.to(progress,{
          i:0,
          duration:0.5,
          ease:"power2.out",
          onStart:()=>{
            this.checkSizeUpdates = false;
          },
          onUpdate:()=>{
            this.updateSize(progress.i)
          }
        })
      }
      this.activeTween.eventCallback('onComplete', ()=>{
        this.isClosed = true;
      })
    },
    updateSize(progress:number = 1){
      let el = this.$refs.posAbsoluteRef as HTMLElement;
      if(el){
        this.height = this.childHeight * progress +'px';
      }
    },
    saveChildSize({ height }:{width:number, height:number}){
      this.childHeight = height;
      if(this.checkSizeUpdates){
        this.updateSize(1);
      }
    },
    killActiveTween() {
      if (this.activeTween) {
        this.activeTween.kill();
      }
    }
  },
  beforeDestroy() {
    this.killActiveTween();
  },
  watch: {
    isOpen(value) {
      const eventName = value ? "open" : "close";
      const parent = findParent(this.$parent, "accordionItem");
      if (parent) {
        parent.$emit(eventName);
      }
      if(value){
        this.animateOpen();
      }
      else{
        this.animateClose();
      }
    }
  }
});
