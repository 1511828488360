import { flow, memoize } from "lodash";
import moment from "moment";

// TODO: создать класс Delta, где параметр delta будет только в нужном формате + флаг error + методы для перевода в минуты и тд

export const deltaFormat = /^[+-] \d{2}\:\d{2}$/; // "+ 00:00" or "- 00:00"
//приводит дельту к deltaFormat
export const cleanDelta = memoize((delta: string): string => {
  const formatedDelta = flow([
    prependZero,
    removeMiliseconds,
    prependPositiveSign,
    addSpaceBetweenSign
  ])(delta);
  if (!deltaFormat.test(formatedDelta)) {
    throw `Error formating delta:${delta} to format: ${deltaFormat} - result: ${formatedDelta}`;
  }
  return formatedDelta;
});
export const deltaIsZero = (delta: string): boolean => {
  return delta === "+ 00:00" || delta === "- 00:00";
};

export const deltaIsPositive = memoize((delta: string): boolean => {
  return delta[0] === "+";
});
export const deltaIsNegative = memoize((delta: string): boolean => {
  return delta[0] === "-";
});
export const deltaToMinutes = memoize((delta: string): number => {
  const formatedDelta = cleanDelta(delta);
  const deltaTime = formatedDelta.substring(2);
  return moment(deltaTime, "HH:mm").diff(moment().startOf("day"), "minutes");
});
export const minutesToTime = memoize((commonMinutes: number): string => {
  commonMinutes = Math.abs(commonMinutes);
  let restMinutes = commonMinutes % 60;
  let hours = (commonMinutes - restMinutes) / 60;
  let hoursFormatted = hours >= 10 ? hours : "0" + hours;
  let minutesFormatted = restMinutes >= 10 ? restMinutes : "0" + restMinutes;
  return hoursFormatted + ":" + minutesFormatted;
});

//-0:00:00 -> -00:00:00, 0:00:00 -> 00:00:00
const prependZero = (delta: string): string => {
  return delta.replace(/^(-?)(\d\:\d{2}\:\d{2})$/, "$10$2");
};
//-00:00:00 -> -00:00, 00:00:00 -> 00:00, 00:00 -> 00:00
const removeMiliseconds = (delta: string): string => {
  return delta.replace(/^(.*\d{2}\:\d{2})\:\d{2}$/, "$1");
};
//00:00 -> +00:00; +00:00 -> +00:00; + 00:00 -> + 00:00
const prependPositiveSign = (delta: string): string => {
  return delta[0] !== "-" && delta[0] !== "+" ? "+" + delta : delta;
  // return delta.replace(/[\+\-]?(.*)/, "+$2");
};
//-00:00 -> - 00:00; +00:00 -> + 00:00, + 00:00 -> + 00:00
const addSpaceBetweenSign = (delta: string): string => {
  return delta.replace(/([+-]?)\s?(.*)/, "$1 $2");
};
