
















































import Vue from "vue";
import { TableWrap, TableLine, TableRow as TableRowUi } from "@/ui/Table";
import TableHeader from "./Header.vue";
import ReportTableRow from "./Row.vue";
import ReportExpandedTable from "./ExpandedTable";
import { getReports } from "@/api/dashboard";
import { AccordionGroup, AccordionItem, AccordionBody } from "@/components/Accordion";
import { Report, Employee, TerminalWithStatus } from "@/schemas/dashboard";
import { TerminalModule } from "@/store/modules/terminal";
import { Loader } from "@/ui";
type DateRange = [Date, Date];

interface ComponentData {
  reports: Report[];
  [propName: string]: any;
}

export default Vue.extend({
  props: {
    dateRange: {
      type: Array as () => Date[],
      required: true
    },
    openEmployee: String
  },
  data: function(): ComponentData {
    return {
      reportsLoading: true,
      reports: []
    };
  },
  computed: {
    availableEmployees(): Employee[] {
      return this.$cStore.employeeSearch.filteredEmployees;
    },
    filteredReports(): Report[] {
      return this.reports.filter(report => {
        return this.availableEmployees.find(employee => employee.id === report.employee.id);
      });
    },
    activeTerminal(): TerminalModule["activeTerminal"] {
      return this.$cStore.terminal.activeTerminal;
    },
    termStore(): TerminalModule {
      return this.$cStore.terminal;
    },
    tableLoading(): boolean {
      return this.reportsLoading || !this.termStore.isReady;
    }
  },
  async mounted() {
    if (this.termStore.checkReady()) {
      this.fetchReports();
    }
  },
  methods: {
    async fetchReports() {
      if (!this.termStore.checkReady()) return;
      this.reportsLoading = true;
      let terminalId = this.termStore.checkReal() ? this.termStore.activeTerminal.id : undefined;

      this.reports = await getReports({
        terminal: terminalId,
        fromDate: this.$moment(this.dateRange[0]),
        tillDate: this.$moment(this.dateRange[1])
      });
      this.reportsLoading = false;
    }
  },
  watch: {
    reports(newVal: Report[]) {
      this.$cStore.employeeSearch.SET_ALL_EMPLOYEES(newVal.map(report => report.employee));
    },
    dateRange() {
      this.fetchReports();
    },
    activeTerminal() {
      this.fetchReports();
    }
  },
  components: {
    ReportTableRow,
    TableLine,
    TableWrap,
    AccordionItem,
    AccordionBody,
    AccordionGroup,
    ReportExpandedTable,
    TableRowUi,
    TableHeader,
    Loader
  }
});
